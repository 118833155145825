<template>
  <div class="picker">
    <el-drawer class="drawer" title="颜色调整" direction="rtl" :visible.sync="visible" :modal="false">
      <div class="picker_color">
        <span class="title">背景颜色</span>
        <el-color-picker v-model="bgc" :predefine="predefineColors"> </el-color-picker>
      </div>
      <div class="picker_color">
        <span class="title">模型颜色</span>
        <el-color-picker v-model="mc" :predefine="predefineColors"> </el-color-picker>
      </div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      predefineColors: ['#ff4500', '#ff8c00', '#ffd700', '#90ee90', '#00ced1', '#1e90ff', '#c71585'],
      bgc: '#0a161b',
      mc: '#fff',
      visible: false
    };
  },
  props: ['backgroundColor', 'modelColor'],
  methods: {
    show() {
      this.visible = true;
    }
  },
  watch: {
    backgroundColor() {
      this.bgc = this.backgroundColor;
    },
    modelColor() {
      this.mc = this.modelColor;
    },
    bgc() {
      this.$emit('BgColor', this.bgc);
    },
    mc() {
      this.$emit('MColor', this.mc);
    },
    deep: true
  }
};
</script>

<style lang="scss" scoped>
// @import '../style/index.scss';
.picker {
  /deep/ .drawer {
    .el-drawer {
      width: 260px !important;
      height: 300px;
      color: #fff;
      background: #060606;
      .el-drawer__header {
        color: #fff;
        font-size: 18px;
      }
      .el-drawer__body {
        padding-left: 21px;
      }
      .picker_color {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        .title {
          margin-bottom: 10px;
        }
      }
    }
    .el-form-item__label {
      color: #fff;
    }
    .title {
      font-size: 16px;
      margin-bottom: 10px;
    }
    .col {
      padding: 0 20px;
      margin-bottom: 10px;
    }
  }
}
</style>
