<template>
  <div class="Mapmain" :style="`background:${backgroundColor}`">
    <Mapheader :btnGroup="btnGroup" :MapData="MapData" />
    <ThreeModel :MapData="MapData" :backgroundColor="backgroundColor" :modelColor="modelColor" />
    <ColorPicker ref="colorpicker" :backgroundColor="backgroundColor" :modelColor="modelColor" @BgColor="BgColor" @MColor="MColor" />
    <Upload ref="Modelupload" :pattern="pattern" :accept="accept" @refresh="init" :title="title" />
  </div>
</template>

<script>
import Mapheader from '../components/header';
import ThreeModel from '../components/ThreeModel';
import ColorPicker from '../components/colorPicker';
import Upload from '../components/upload';
export default {
  components: {
    Mapheader,
    ThreeModel,
    ColorPicker,
    Upload
  },
  data() {
    return {
      backgroundColor: '#0a161b',
      modelColor: '#fff',
      btnGroup: [
        {
          icon: 'iconfont icon-dcicon_save1',
          name: '保存',
          event: this.save
        },
        {
          icon: 'iconfont icon-dcshangchuancaizhiwenjian',
          name: '上传材质文件',
          event: this.uploadTexture
        },
        {
          icon: 'iconfont icon-dcshangchuanmoxingwenjian',
          name: '上传模型文件',
          event: this.uploadModel
        },
        {
          icon: 'iconfont icon-dcpeise',
          name: '修改颜色',
          event: this.changeColor
        }
      ],
      // 模型==0 材质==1 背景文件==2
      pattern: 0,
      // 文件类型 模型==3ds 材质/背景===jpg/png
      accept: '',
      visible: false,
      MapData: null,
      title: ''
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const tenantId = JSON.parse(localStorage.getItem('user')).tenantId;
      const res = await this.$apis.Map3d.get({ tenantId });
      this.MapData = res;
      this.backgroundColor = this.MapData.backgrColor;
      this.modelColor = this.MapData.moderlColor;
    },
    async save() {
      const tenantId = JSON.parse(localStorage.getItem('user')).tenantId;
      const { fail } = await this.$apis.Map3d.tagChange({ backgrColor: this.backgroundColor, moderlColor: this.modelColor, tenantId });
      if (fail) {
        return false;
      }
      this.init();
    },
    uploadTexture() {
      this.title = '上传材质文件';
      this.pattern = 1;
      this.accept = '.mtl';
      this.$refs.Modelupload.show();
    },
    uploadModel() {
      this.title = '上传模型文件';
      this.pattern = 0;
      this.accept = '.obj';
      this.$refs.Modelupload.show();
    },
    changeColor() {
      this.$refs.colorpicker.show();
    },
    BgColor(backgroundColor) {
      this.backgroundColor = backgroundColor || this.backgroundColor;
    },
    MColor(modelColor) {
      this.modelColor = modelColor || this.modelColor;
    }
  }
};
</script>
<style lang="scss" scoped>
.Mapmain {
  width: 100vw;
  height: 100vh;
  background-color: #0a161b;
}
</style>
